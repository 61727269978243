// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontoffice-primary: mat.define-palette(mat.$indigo-palette);
$frontoffice-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$tl-primary: (
  50 : #e4eaf2,
  100 : #bccbdf,
  200 : #8fa8c9,
  300 : #6285b3,
  400 : #406ba3,
  500 : #1e5193,
  600 : #1a4a8b,
  700 : #164080,
  800 : #123776,
  900 : #0a2764,
  A100 : #96b3ff,
  A200 : #638eff,
  A400 : #3068ff,
  A700 : #1756ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$tl-accent: (
  50 : #f4f7e9,
  100 : #e3ecc8,
  200 : #d0dfa3,
  300 : #bdd27e,
  400 : #afc863,
  500 : #a1be47,
  600 : #99b840,
  700 : #8faf37,
  800 : #85a72f,
  900 : #749920,
  A100 : #f1ffd4,
  A200 : #e0ffa1,
  A400 : #d0ff6e,
  A700 : #c7ff55,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// The warn palette is optional (defaults to red).
$frontoffice-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontoffice-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($tl-primary),
    accent: mat.define-palette($tl-accent),
    warn: $frontoffice-warn,
  ),
  typography: mat.define-typography-config(
    $font-family: 'Plus Jakarta Sans, sans-serif',
  ),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontoffice-theme);

/* You can add global styles to this file, and also import other style files */

// Tailwind css
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  background-color: mat.get-color-from-palette($tl-primary, 50);
}

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

/**
  * Custom styles
 */

:root {
  --mat-toolbar-container-background-color: white;
  --mat-table-header-headline-weight: 700;
  --mat-table-row-item-label-text-weight: 500;
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-fab-small-container-elevation-shadow: none;
  --mat-table-row-item-container-height: 104px;

  --mdc-filled-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-tracking: normal;

  --primary: mat.get-color-from-palette($tl-primary, 500);
  --secondary: mat.get-color-from-palette($tl-accent, 500);
  --warn: mat.get-color-from-palette($frontoffice-warn, 500);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-outline-color: var(--primary);
}

h1 {
  @apply text-primary;
  @apply font-semibold;
  @apply text-2xl;
  @apply md:text-3xl;
  @apply my-8;
}

h2 {
  @apply text-xl;
  @apply font-semibold;
  @apply text-black;
  @apply mt-8 #{!important};
  @apply mb-6;
}

h3 {
  @apply font-bold;
  @apply whitespace-nowrap;
  @apply text-base;
}

.custom-small-dialog {
  --mdc-dialog-container-shape: 0;
  --mat-dialog-container-max-width: 100%;
  --mat-dialog-container-small-max-width: 100%;

  .mat-mdc-dialog-content {
    max-height: unset;
  }
}

.mat-mdc-table {
  border-collapse: separate;
}

mat-paginator {
  @apply rounded-b-md;
  display: flex;
  @apply justify-center;
}

.mdc-notched-outline__notch {
  border-right: 0;
}

.mdc-button__label {
  @apply truncate;
}
